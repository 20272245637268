globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"10d085fbd77731e5dd0b2654a2093a803bdc295e"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://7f21823b41cf894f29d63df4f9278edd@o4507901563633664.ingest.de.sentry.io/4507901778329680",

  // Setting this option to true will print useful information to the console while you're setting up Sentry.
  debug: false,
});
